$(function() {

	// toggle bar-menu
	$('#toggle-menu').click(function () {
		$(this).toggleClass('is-active');
		$('#sidebar-menu').slideToggle();
	});


	/**
	 * SEARCH FORM
	 *
	 * @type {jQuery|HTMLElement}
	 */

	// $('#toggle-search').click(function (event) {
	// 	event.preventDefault();
	// 	$('#search-form').slideToggle();
	// })


	/**
	 * LOGIN
	 *
	 * @type {jQuery|HTMLElement}
	 */

	$('#toggle-user, button.login-to-comment').click(function (event) {
		event.preventDefault();
		$('#login-header').slideToggle();
	})



	$('#close-login').click(function (event) {
		event.preventDefault();
		$('#login-header').slideToggle();
	})

	/**
	 * show hide product menu
	 */


	$('#toggleProductsExpand').click( function (event) {
		event.preventDefault();
		$('#menu-products').slideToggle();
	});

	// check if

	/**
	 * MENU PRODUCTS
	 *
	 * @type {jQuery|HTMLElement}
	 */

	// menu sidebar
	var productMenu = $('#menu-products');

	// hide productMenu for transformations
	var subMenuParents = productMenu.find('li.has-submenu');

	// generate toggle buttons
	subMenuParents.each(function(){
		$(this).find('> a').append('<i class="toggleExpand"></i>')
	});




	// select toggle buttons
	var toggleExpand = $('#menu-products .toggleExpand');

	// actions on toggle buttons
	toggleExpand.each(function(){

		$(this).click(function( event ){

			event.preventDefault();
			var currentParent = $(this).closest('.has-submenu');

			currentParent.find('ul.submenu').slideToggle();
			currentParent.toggleClass('obert');

			// select other parents
			openedSubMenuParents = subMenuParents.filter('.obert').not(currentParent);
			openedSubMenuParents.removeClass('obert');
			openedSubMenuParents.find('ul.submenu').slideUp();
		});
	});

	// show sidebarMenu
	$('#sidebar-menu').addClass('visible');

});
